import './Separator.css'
function Separator() {
    return ( 
        <div className="separator-container">
            <div className="separator-object">

            </div>
        </div>
     );
}

export default Separator;